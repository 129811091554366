<template>
    <div v-if="selectedDocument.id">
        <!-- Barra de tareas -->
        <div
            class="box is-flex is-align-items-center is-justify-content-space-between"
        >
            <div
                v-if="
                    selectedDocument.estado.descripcion === 'FIRMADO CONFORME'
                "
            >
                <b-icon icon="draw" /><b>Firmado conforme</b> el
                {{ selectedDocument.estado.created_at | moment }}
            </div>
            <div>
                <b-dropdown
                    aria-role="list"
                    class="is-pulled-right is-hidden-desktop"
                    position="is-bottom-left"
                >
                    <template #trigger>
                        <b-icon icon="dots-vertical" role="button"></b-icon>
                    </template>

                    <b-dropdown-item
                        v-if="
                            selectedDocument.estado.descripcion ===
                                'FIRMADO CONFORME' ||
                            selectedDocument.estado.descripcion ===
                                'NO REQUIERE FIRMADO'
                        "
                        aria-role="listitem"
                        @click="downloadDocument"
                    >
                        <b-icon icon="download" size="is-small"></b-icon>
                        Descargar
                    </b-dropdown-item>

                    <b-dropdown-item
                        v-if="
                            selectedDocument.estado.descripcion === 'NO FIRMADO'
                        "
                        :disabled="loadingValidateRestricciones"
                        @click="validateBeforeFirmar"
                    >
                        <b-icon icon="signature" size="is-small"></b-icon>
                        Firmar
                    </b-dropdown-item>
                </b-dropdown>
                <div class="buttons is-hidden-touch">
                    <b-button
                        v-if="
                            selectedDocument.estado.descripcion ===
                                'FIRMADO CONFORME' ||
                            selectedDocument.estado.descripcion ===
                                'NO REQUIERE FIRMADO'
                        "
                        type="is-primary is-light"
                        icon-left="download"
                        @click="downloadDocument"
                    >
                        Descargar
                    </b-button>
                    <b-button
                        v-if="
                            selectedDocument.estado.descripcion === 'NO FIRMADO'
                        "
                        type="is-success"
                        :loading="loadingValidateRestricciones"
                        @click="validateBeforeFirmar"
                    >
                        Firmar documento
                    </b-button>
                </div>
            </div>
        </div>
        <!-- Visualizador del documento -->
        <div class="is-relative">
            <b-loading v-model="isLoadingDocument" :is-full-page="false" />
            <vue-pdf-embed
                v-if="blobUrl !== null"
                :source="blobUrl"
                ref="pdfRef"
                style="max-height: 70vh; overflow: scroll"
                @rendered="handleDocumentRender"
            />
        </div>
        <!-- Modal Firmado -->
        <b-modal v-model="isModalActive" :width="450">
            <div class="card">
                <div class="card-content">
                    <div class="content">
                        <form @submit.prevent="handleFirmarDocumentos">
                            <b-field
                                label="Ingrese Contraseña"
                                label-position="on-border"
                            >
                                <b-input
                                    v-model="form.password"
                                    name="contraseña"
                                    type="password"
                                    password-reveal
                                    required
                                />
                            </b-field>
                            <br />
                            <button
                                class="button is-block is-fullwidth is-primary"
                                :class="{ 'is-loading': loading }"
                                :disabled="loading"
                                type="submit"
                            >
                                Firmar
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </b-modal>

        <!-- Modal Condicion -->
        <modal-condicion
            :isModalActive="isModalCondicionActive"
            @toggle-view-modal="handleToggleViewModal"
        />
    </div>
    <div v-else>
        <div
            class="light-border"
            style="height: 80px; margin-bottom: 24px"
        ></div>
        <div class="container has-text-centered select-document light-border">
            <b-loading v-model="isLoadingDocument" :is-full-page="false" />
            <p class="is-size-5 has-text-grey-light">Seleccione un documento</p>
        </div>
    </div>
</template>

<script>
// TODO: Implementar barra de tareas para descargar, hacer zoom y cambiar de pagina
import moment from 'moment';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

import Utils from '@/utils/Utils';
import Message from '@/utils/Message';
import ModalCondicion from '@/components/Documentos/ModalCondicion.vue';
import { EstadosDocumentos } from '@/constants/estados-documentos';

export default {
    name: 'PrevisualizarPdf',
    components: {
        VuePdfEmbed,
        ModalCondicion,
    },
    data() {
        return {
            estadosDocumentos: EstadosDocumentos,
            isModalActive: false,
            isModalCondicionActive: false,
            loading: false,
            loadingValidateRestricciones: false,
            page: 1,
            pageCount: 1,
            form: {
                password: '',
            },
        };
    },
    computed: {
        documentos() {
            return this.$store.state.documentos.data;
        },
        selectedDocument() {
            return this.$store.state.documentos.selected;
        },
        src() {
            return (
                'data:image/jpg;base64,' + this.$store.state.documentos.image
            );
        },
        blobUrl() {
            return this.$store.state.documentos.blobUrl;
        },
        isLoadingDocument() {
            return this.$store.state.documentos.isLoading;
        },
    },
    filters: {
        moment: function(date) {
            return moment(date).format('DD/MM/YYYY hh:mm A');
        },
    },
    methods: {
        async validateBeforeFirmar() {
            if (this.selectedDocument.tipo_documento.restricciones_firmado) {
                if (
                    this.selectedDocument.tipo_documento.restricciones_firmado
                        .length !== 0
                ) {
                    const isValidate = await this.validateRestricciones();
                    if (!isValidate) {
                        this.isModalCondicionActive = true;
                        return;
                    }
                }
            }
            this.isModalActive = true;
            return;
        },
        async validateRestricciones() {
            this.loadingValidateRestricciones = true;
            try {
                await this.$store.dispatch(
                    'documentos/validateRestricciones',
                    this.selectedDocument.id
                );
                return true;
            } catch (err) {
                return false;
            } finally {
                this.loadingValidateRestricciones = false;
            }
        },
        async handleFirmarDocumentos() {
            this.loading = true;
            try {
                const res = await this.$store.dispatch('documentos/sign', {
                    ...this.form,
                    documento_id: this.selectedDocument.id,
                });
                Message.showSuccessMessage(this, res.message);

                this.isModalActive = false;
                this.clearForm();

                await this.fetchDocuments();

                const signedDocument = this.documentos.find(
                    (documento) => documento.id === this.selectedDocument.id
                );
                await this.selectDocument(signedDocument);
            } catch (err) {
                this.isModalActive = false;
                this.clearForm();

                if (err.open_modal) {
                    this.isModalCondicionActive = true;
                }

                Message.showErrorMessage(this, err.message);
            } finally {
                this.loading = false;
            }
        },
        async fetchDocuments() {
            await this.$store.dispatch('documentos/getByPerfil');
        },
        async selectDocument(document) {
            this.$store.dispatch('documentos/setLoading', true);
            try {
                this.$store.dispatch('documentos/selectDocumento', document);
                await this.$store.dispatch('documentos/view', document.id);
            } catch (err) {
                console.log(err);
                this.$store.dispatch('documentos/selectDocumento', {});
                Message.showErrorMessage(
                    this,
                    `Error al obtener documento: ${err.message}`
                );
            } finally {
                this.$store.dispatch('documentos/setLoading', false);
            }
        },
        handleDocumentRender() {
            this.pageCount = this.$refs.pdfRef.pageCount;
        },
        clearForm() {
            this.form.password = '';
        },
        downloadDocument() {
            Utils.downloadFileFromUrl(this.blobUrl, 'document', 'pdf');
        },
        handleToggleViewModal(value) {
            this.isModalCondicionActive = value;
        },
    },
};
</script>

<style scoped>
.light-border {
    border: 1px #b8b8b8 dashed;
    border-radius: 6px;
}

.select-document {
    height: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.b-skeleton {
    height: 100%;
    position: absolute;
    top: 0;
}
</style>
